<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <!--  -->

        <div class="row mb-4">
          <div class="col-12">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/practitioner')"
            >
              <div class="card-body text-center">
                <div class="card bg-light">
                  <div class="card-body">
                    <img
                      v-if="displayOptions && displayOptions.showLogo"
                      :src="$store.user.clinic.logo"
                      :alt="$store.user.clinic.name"
                      class="rounded-circle mb-3 bg-white p-1 mx-auto"
                      width="100"
                      height="100"
                    />
                    <h4 class="font-weight-bold text-primary mb-2">
                      {{ $store.user.clinic.name }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card border-0 shadow-sm cursor-pointer"
              v-if="displayOptions && displayOptions.showContact"
            >
              <div class="card-body text-center">
                <div class="card bg-light">
                  <div class="card-body">
                    <h4>Contact Details</h4>
                    <p v-if="displayOptions.showContactEmail">
                      {{ $store.user.clinic.email_address }}
                    </p>
                    <p v-if="displayOptions.showContactPhone">
                      {{ $store.user.clinic.phone }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="children.length > 0">
          <div class="row mb-4">
            <div class="col">
              <select
                class="form-control"
                v-model="selectedChild"
                v-if="!selectedChild"
                :disabled="loadingLoginLink"
              >
                <option value selected disabled>Switch to Child Profile</option>
                <option v-for="child in children" :value="child">
                  {{ child.name }}
                </option>
              </select>
              <div v-if="loadingLoginLink || selectedChild">
                <div class="card">
                  <div class="card-body">
                    <div v-if="loadingLoginLink">
                      <p class="text-center">
                        <i
                          class="
                            fad
                            fa-spin fa-spinner
                            text-primary
                            mx-auto
                            fa-3x
                          "
                        />
                      </p>
                    </div>
                    <div v-else>
                      <p class="text-center">
                        <i
                          class="fad fa-envelope text-primary mx-auto fa-3x"
                        />
                      </p>
                      <p class="alert alert-info">
                        We have sent a secure login link to your email address.
                        Following this link will authenticate you as the
                        selected child profile, allowing you to access the
                        portal on their behalf.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->

        <div class="row mb-4">
          <div class="col-12">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/appointments/create')"
            >
              <div class="card-body text-center">
                <i
                  class="fad fa-calendar-plus fa-4x text-primary d-block mb-3"
                />
                <p
                  class="
                    text-primary
                    mb-0
                    font-weight-bold
                    text-nowrap
                    overflow-visible
                  "
                >
                  Book an Appointment
                </p>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <div class="row mb-4">
          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/appointments')"
            >
              <div class="card-body text-center">
                <span
                  v-if="$store.unreadCounters.appointments > 0"
                  class="badge badge-danger badge-top-right"
                  >New</span
                >
                <i
                  class="fad fa-calendar-star fa-4x text-primary d-block mb-3"
                />
                <p
                  class="
                    text-primary
                    mb-0
                    font-weight-bold
                    text-nowrap
                    overflow-visible
                  "
                >
                  Appointments
                </p>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/orders')"
            >
              <div class="card-body text-center">
                <span
                  v-if="$store.unreadCounters.orders > 0"
                  class="badge badge-danger badge-top-right"
                  >New</span
                >
                <i class="fad fa-list fa-4x text-primary d-block mb-3"/>
                <p class="font-weight-bold text-primary mb-0">Orders</p>
              </div>
            </div>
          </div>
        </div>

        <!--

        <div class="row mb-4">
          <div class="col-6">
            <div class="card border-0 shadow-sm cursor-pointer" @click="goToLink('/carts')">
              <span v-if="$store.unreadCounters.carts > 0" class="badge badge-danger badge-top-right">New</span>
              <div class="card-body text-center">
                <i class="fad fa-shopping-basket fa-4x text-primary d-block mb-3"/>
                <p class="font-weight-bold text-primary mb-0">Carts</p>
              </div>
            </div>
          </div>

           -->

        <div class="row mb-4">
          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/forms')"
            >
              <div class="card-body text-center">
                <span
                  v-if="$store.unreadCounters.forms > 0"
                  class="badge badge-danger badge-top-right"
                  >New</span
                >
                <i
                  class="fad fa-clipboard-list fa-4x text-primary d-block mb-3"
                />
                <p class="font-weight-bold text-primary mb-0">Forms</p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              v-if="$store.unreadCounters.files > 0 || $store.pendingAgreementsCount < 1"
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/agreements')"
            >
              <div class="card-counter" v-if="$store.pendingAgreementsCount > 0">
                <p>{{ $store.pendingAgreementsCount }}</p>
              </div>
              <div class="card-body text-center">
                <i
                  class="fad fa-handshake-alt fa-4x text-primary d-block mb-3"
                ></i>
                <p class="font-weight-bold text-primary mb-0">T&Cs</p>
              </div>
            </div>
            <div
              v-else
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/agreements')"
            >
              <div class="card-counter" v-if="$store.pendingAgreementsCount > 0">
                <p>{{ $store.pendingAgreementsCount }}</p>
              </div>
              <div class="card-body text-center">
                <i
                  class="fad fa-handshake-alt fa-4x text-primary d-block mb-3"
                ></i>
                <p class="font-weight-bold text-primary mb-0">T&Cs</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/resources')"
            >
              <div class="card-body text-center">
                <span
                  v-if="$store.unreadCounters.resources > 0"
                  class="badge badge-danger badge-top-right"
                  >New</span
                >
                <i class="fad fa-paperclip fa-4x text-primary d-block mb-3"></i>
                <p class="font-weight-bold text-primary mb-0">Resources</p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/files')"
            >
              <div class="card-body text-center">
                <span
                  v-if="$store.unreadCounters && $store.unreadCounters.files > 0"
                  class="badge badge-danger badge-top-right"
                  >New</span
                >
                <i class="fad fa-folders fa-4x text-primary d-block mb-3"/>
                <p class="font-weight-bold text-primary mb-0">Files</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/health-diary')"
            >
              <div class="card-body text-center">
                <i
                  class="fad fa-book-medical fa-4x text-primary d-block mb-3"
                ></i>
                <p class="font-weight-bold text-primary mb-0">Health Diary</p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/tracker')"
            >
              <span
                v-if="$store.unreadCounters.mymop > 0"
                class="badge badge-danger badge-top-right"
                >New</span
              >
              <div class="card-body text-center">
                <i
                  class="fad fa-mind-share fa-4x text-primary d-block mb-3"
                ></i>
                <p class="font-weight-bold text-primary mb-0">Health Tracker</p>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <div class="row mb-4">
          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/health-planner')"
            >
              <span
                v-if="$store.unreadCounters.health_planner > 0"
                class="badge badge-danger badge-top-right"
                >New</span
              >
              <div class="card-body text-center">
                <i
                  class="
                    fad
                    fa-hand-holding-seedling fa-4x
                    text-primary
                    d-block
                    mb-3
                  "
                />
                <p class="font-weight-bold text-primary mb-0">Health Planner</p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer h-100"
              @click="goToLink('/online-programmes')"
            >
              <div class="card-body text-center">
                <i
                  class="fad fa-signal-stream fa-4x text-primary d-block mb-3"
                />
                <p class="text-primary mb-0 font-weight-bold overflow-visible">
                  Online Programmes
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer h-100"
              @click="goToLink('/allergies')"
            >
              <div class="card-body text-center reducedYPadding">
                <i class="fad fa-allergies fa-4x text-primary d-block mb-3"/>
                <p class="font-weight-bold text-primary mb-0">
                  Allergies & Intolerances
                </p>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div
              class="card border-0 shadow-sm cursor-pointer"
              @click="goToLink('/settings')"
            >
              <div class="card-body text-center">
                <i
                  class="
                    fad
                    fa-sliders-h-square fa-4x
                    text-primary
                    d-block
                    mb-3
                  "
                />
                <p class="font-weight-bold text-primary mb-0">Settings</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      children: [],
      selectedChild: "",
      loadingLoginLink: false,
      displayOptions: null,
    };
  },
  methods: {
    goToLink(link) {
      this.$router.push(link);
    },
    goBack() {
      this.$router.go(-1);
    },
    fetchChildren() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/children")
        .then(({ data }) => {
          this.children = data;
        });
    },
    switchToChild() {
      this.loadingLoginLink = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/login-as-child", {
          client_id: this.selectedChild.id,
          parent_id: this.$store.user.id,
          clinic_id: this.$store.user.clinic_id,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.loadingLoginLink = false;
        });
    },
    getDisplayOptions() {
      let displayOptions = {
        showLogo: true,
        showContact: false,
      };

      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/clinic-settings")
        .then(({ data }) => {
          if (data) {
            if (!data.settings) {
              this.displayOptions = displayOptions;
            } else {
              this.displayOptions = data.settings.settings;
            }
          }
        })
        .catch(() => {
          this.displayOptions = displayOptions;
        });
    },
  },
  computed: {
    user() {
      return this.$store.user;
    },
  },
  watch: {
    selectedChild() {
      this.switchToChild();
    },
  },
  mounted() {
    this.getDisplayOptions();
    this.fetchChildren();
  },
};
</script>

<style>
.reducedYPadding {
  padding-left: 10px;
  padding-right: 10px;
}
</style>